import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HorizontalScrolling from '../components/HorizontalScrolling'
import StartSection from '../components/StartSection'
import MiniTextSection from '../components/MiniTextSection'
import MeridionalSection from '../components/MeridionalSection'
import CoquillageSection from '../components/CoquillageSection'
import ArtecoSection from '../components/ArtecoSection'
import ServicesSection from '../components/ServicesSection'
import ShowroomSection from '../components/ShowroomSection'
import VariantsSection from '../components/VariantsSection'
import ProjectsSection from '../components/ProjectsSection'
import InstagramSection from '../components/InstagramSection'
import DuCourtSection from '../components/DuCourtSection'
import ValpaintSection from '../components/ValpaintSection'
import BorderedSliderSection from '../components/BorderedSliderSection'
import ContactForm from '../components/ContactForm'

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { wordpressPage, allWordpressWpProject } = data
    const projects = allWordpressWpProject.nodes

    return (
      <HorizontalScrolling>
        <StartSection
          options={wordpressPage.acf.start_section}
          className="screen-width-section"
        />
        <MiniTextSection
          options={wordpressPage.acf.mini_text_section}
          className="screen-width-section"
        />
        <ServicesSection
          options={wordpressPage.acf.services_section}
          className="screen-width-section"
        />
        <ShowroomSection
          options={{
            ...wordpressPage.acf.showroom_section,
            ...{
              link: '/',
              link_text: 'Открыть видео',
              icon: 'icon-play-button',
            },
          }}
          className="screen-width-section"
        />
        <MeridionalSection
          options={wordpressPage.acf.meridional_section}
          className="screen-width-section"
        />
        <CoquillageSection
          options={wordpressPage.acf.coquillage_section}
          className="screen-width-section"
        />
        <ArtecoSection
          options={wordpressPage.acf.arteco3_section}
          className="screen-width-section"
        />
        <VariantsSection
          options={wordpressPage.acf.variants_section}
          className="screen-width-section"
        />
        <ProjectsSection
          options={wordpressPage.acf.projects_section}
          projects={projects}
          className="screen-width-section"
        />
        <InstagramSection
          options={wordpressPage.acf.instagram_section}
          className="screen-width-section"
        />
        <DuCourtSection
          options={wordpressPage.acf.ducourt_section}
          materials_section={wordpressPage.acf.materials_section}
          className="screen-width-section"
        />
        <ValpaintSection
          options={wordpressPage.acf.valpaint_section}
          className="screen-width-section"
        />
        <BorderedSliderSection
          slides={wordpressPage.acf.bordered_slider_section}
          className="screen-width-section"
        />
        <ContactForm className="screen-width-section" />
      </HorizontalScrolling>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query IndexQuery {
    wordpressPage(template: { eq: "home.php" }) {
      title
      path
      slug
      template
      status
      link
      date
      content
      acf {
        start_section {
          title
          description
          button {
            type
            text
            link
            new_tab
          }
          slides {
            image {
              id
              localFile {
                childImageSharp {
                  fluid(quality: 90) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
        mini_text_section {
          overtitle
          title
          description
          image {
            id
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        coquillage_section {
          left_text
          title
          description
          button {
            type
            text
            link
            new_tab
          }
          small_image {
            localFile {
              childImageSharp {
                fixed(width: 280, height: 280, quality: 90) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          large_image {
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        arteco3_section {
          left_text
          title
          description
          button {
            type
            text
            link
            new_tab
          }
          small_image {
            localFile {
              childImageSharp {
                fixed(width: 280, height: 280, quality: 90) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          large_image {
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        meridional_section {
          left_text
          title
          description
          button {
            type
            text
            link
            new_tab
          }
          small_image {
            localFile {
              childImageSharp {
                fixed(width: 280, height: 280, quality: 90) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          large_image {
            localFile {
              childImageSharp {
                fluid(quality: 90) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        showroom_section {
          left_text
          title
          video
          image {
            localFile {
              childImageSharp {
                fixed(width: 920, height: 500) {
                  src
                }
              }
            }
          }
        }
        services_section {
          title
          services {
            title
            link
            image {
              localFile {
                childImageSharp {
                  fixed(width: 220, quality: 90) {
                    ...GatsbyImageSharpFixed_noBase64
                  }
                }
              }
            }
            description
          }
        }
        variants_section {
          image {
            localFile {
              childImageSharp {
                fixed(width: 616, quality: 90) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          title
          description
          button {
            type
            text
            link
            new_tab
          }
          variants {
            title
            bg_image {
              id
              localFile {
                childImageSharp {
                  fixed(width: 600, height: 1200, quality: 95) {
                    src
                  }
                }
              }
            }
            image {
              id
              localFile {
                childImageSharp {
                  fixed(width: 500, height: 500, trim: 1) {
                    width
                    src
                  }
                }
              }
            }
          }
        }
        projects_section {
          title
          description
          button {
            type
            text
            link
            new_tab
          }
        }
        instagram_section {
          instagram_username
          avatar {
            localFile {
              childImageSharp {
                fixed(width: 100, height: 100, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          gallery {
            id
            localFile {
              childImageSharp {
                fixed(width: 250, height: 250, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        materials_section {
          title
          description
        }
        ducourt_section {
          left_text
          description
          button {
            type
            text
            link
            new_tab
          }
          horizontal_image {
            localFile {
              childImageSharp {
                fluid(quality: 95) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          vertical_image {
            localFile {
              childImageSharp {
                fluid(quality: 95) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                fixed(width: 238, height: 238, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        valpaint_section {
          left_text
          description
          button {
            type
            text
            link
            new_tab
          }
          vertical_image {
            localFile {
              childImageSharp {
                fluid(quality: 95) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
          logo {
            localFile {
              childImageSharp {
                fixed(width: 238, height: 238, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        bordered_slider_section {
          title
          description
          button {
            type
            text
            link
            new_tab
          }
          bg_image {
            localFile {
              childImageSharp {
                fluid(quality: 95) {
                  src
                }
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 95) {
                  src
                }
              }
            }
          }
        }
      }
    }
    allWordpressWpProject(limit: 5) {
      nodes {
        acf {
          short_description
          card_image {
            localFile {
              childImageSharp {
                fixed(width: 350, height: 485, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
        title
        link
        id
      }
    }
  }
`
