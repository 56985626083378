import React, { useState, useEffect } from 'react'
import { useSprings, useTransition, animated } from 'react-spring'
import Measure from 'react-measure'
import { throttle } from 'throttle-debounce'
import usePageVisibility from '../../util/usePageVisibility'
import style from './PrimarySlider.module.scss'
import PrimarySliderCursor from './PrimarySliderCursor'
import PrimarySliderContainer from './PrimarySliderContainer'

let currentTimeout = null
const PrimarySlider = ({
  slides,
  inView = false,
  className = '',
  autoplay = true,
  autoplaySpeed = 5000,
}) => {
  const [rootBounds, setRootBounds] = useState(null)
  const [currentSlide, setCurrentSlide] = useState(0)
  const isVisible = usePageVisibility(true)
  const amountSlides = slides && slides.length ? slides.length : 0
  const lastSlide = amountSlides - 1
  const throttledSetRootBounds = throttle(500, setRootBounds)

  const transitions = useTransition(currentSlide, p => p, {
    from: { opacity: 0, transform: 'translateX(10px)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(-10px)' },
  })

  // pagination circle animation
  const [circles, setCircles] = useSprings(slides.length, i => ({
    progress: 0,
  }))

  const prevSlide = () =>
    setCurrentSlide(currentSlide <= 0 ? amountSlides - 1 : currentSlide - 1)

  const nextSlide = () =>
    setCurrentSlide(currentSlide >= lastSlide ? 0 : currentSlide + 1)

  useEffect(() => {
    if (autoplay && inView && isVisible) {
      currentTimeout = setTimeout(nextSlide, autoplaySpeed)
    } else {
      clearTimeout(currentTimeout)
    }

    setCircles(i => ({
      from: { progress: 0 },
      to: { progress: i <= currentSlide ? 100 : 0 },
      reset: i === currentSlide,
      config: {
        duration: i === currentSlide ? autoplaySpeed : 250,
      },
    }))

    return () => clearTimeout(currentTimeout)
  }, [currentSlide, inView, isVisible])

  return amountSlides > 0 ? (
    <Measure
      bounds
      onResize={contentRect => {
        throttledSetRootBounds(contentRect.bounds)
      }}
    >
      {({ measureRef }) => (
        <div
          ref={measureRef}
          className={`${style.root} flex-1 relative bg-gray-200 ${className}`}
        >
          <PrimarySliderCursor onPrev={prevSlide} onNext={nextSlide} />
          <div className={style.pagination}>
            <div className={style.paginationStatus}>
              {transitions.map(({ item, props, key }) => (
                <animated.span
                  className={style.paginationStatusCurrent}
                  key={key}
                  style={props}
                >
                  {item + 1}
                </animated.span>
              ))}
              <span className="px-2">/</span>
              {amountSlides}
            </div>
            {circles.map(({ progress }, i) => (
              <button
                key={slides[i].image.id}
                type="button"
                className={style.paginationPage}
                onClick={() => setCurrentSlide(i)}
              >
                <animated.svg
                  strokeDasharray={progress.interpolate(p => `${p}, 100`)}
                  viewBox="0 0 36 36"
                  width="24"
                  height="24"
                >
                  <path
                    className="circle"
                    fill="none"
                    stroke="#ffffff"
                    strokeWidth="2.5"
                    d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                </animated.svg>
              </button>
            ))}
          </div>
          {rootBounds ? (
            <PrimarySliderContainer
              slides={slides}
              currentSlide={currentSlide}
              onPrev={prevSlide}
              onNext={nextSlide}
              gestureRef={measureRef}
              width={rootBounds.width}
            />
          ) : null}
        </div>
      )}
    </Measure>
  ) : null
}

export default PrimarySlider
