import React from 'react'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import style from './StartSection.module.scss'
import PrimarySlider from '../PrimarySlider'
import ACFButton from '../ACFButton'
import RotatingCircleLogo from '../RotatingCircleLogo'

const StartSection = ({ options = {}, className }) => {
  const [ref, inView] = useInView({
    // threshold: 0.99,
  })

  return (
    <section
      ref={ref}
      className={cs({ [style.section]: true, [className]: className })}
    >
      {options.slides && options.slides.length ? (
        <PrimarySlider
          inView={inView}
          slides={options.slides}
          className={`${style.row} flex-1 relative bg-gray-200`}
        />
      ) : (
        <div className={`${style.row} flex-1 relative bg-gray-200`} />
      )}
      <div className={`${style.row} ${style.textContainer} flex-none relative`}>
        <div
          className="flex flex-wrap items-end md:justify-end mx-auto"
          style={{ maxWidth: '920px' }}
        >
          <div className="flex-1 p-4 md:p-6">
            <h1>{options.title}</h1>
            <div
              className="primary-description mt-3"
              dangerouslySetInnerHTML={{ __html: options.description }}
            />
          </div>
          {options.button ? (
            <div className="w-full md:w-auto flex-none p-4 md:p-6">
              <ACFButton {...options.button} />
            </div>
          ) : null}
        </div>
        <div className={style.rotatingCirlceContainer}>
          <RotatingCircleLogo disableAnimation={!inView} />
        </div>
      </div>
    </section>
  )
}

export default StartSection
