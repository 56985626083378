import React, { useState, useEffect } from 'react'
import { useSprings, animated } from 'react-spring'
import cs from 'classnames'
import once from 'once'
import { useInView } from 'react-intersection-observer'
import style from './VariantsSlider.module.scss'

const getSpringState = (inView, i, currentSlide, isHovered = false) => {
  const slideIsHidden = i - currentSlide > 2 || i < currentSlide
  const opacity = slideIsHidden ? 0 : 1
  const config = {
    mass: 1,
    tension: slideIsHidden ? 200 : 80,
    friction: slideIsHidden ? 24 : Math.abs(i - currentSlide) * 5 + 24,
  }

  return inView
    ? {
        xr: isHovered
          ? [(i - currentSlide) * 100, -30]
          : [(i - currentSlide) * 100, -45],
        opacity,
        config,
      }
    : {
        xr: [(i - currentSlide) * 100 + 120, -65],
        opacity,
        config,
      }
}

const setSpringStateOnce = once((set, inView, currentSlide) => {
  set(i => getSpringState(inView, i, currentSlide))
})

const VariantsSlider = ({
  className = '',
  slides = [],
  autoplay = true,
  autoplaySpeed = 3000,
}) => {
  const [inViewRef, inView] = useInView({
    // threshold: 0.99,
  })
  const [currentSlide, setCurrentSlide] = useState(0)
  const amountSlides = slides && slides.length ? slides.length : 0
  const lastSlide = amountSlides - 1
  const [props, set] = useSprings(slides.length, i =>
    getSpringState(inView, i, currentSlide)
  )

  const prevSlide = () =>
    setCurrentSlide(currentSlide <= 0 ? amountSlides - 1 : currentSlide - 1)

  const nextSlide = () =>
    setCurrentSlide(currentSlide >= lastSlide ? 0 : currentSlide + 1)

  useEffect(() => {
    if (inView) {
      setSpringStateOnce(set, inView, currentSlide)
    }
  }, [inView])

  useEffect(() => {
    set(i => getSpringState(inView, i, currentSlide))
  }, [currentSlide])

  return (
    <div ref={inViewRef} className={cs(className, style.root)}>
      {slides && slides.length
        ? slides.map((item, i) =>
            item.bg_image ? (
              <div
                key={i}
                className={cs(style.bg, {
                  [style.bgActive]: currentSlide === i,
                })}
                style={{
                  backgroundImage: `url(${item.bg_image.localFile.childImageSharp.fixed.src})`,
                }}
              />
            ) : null
          )
        : null}
      <div
        onMouseEnter={() => {
          set(i => getSpringState(inView, i, currentSlide, true))
        }}
        onMouseLeave={() => {
          set(i => getSpringState(inView, i, currentSlide, false))
        }}
        className={style.slides}
      >
        {props.map(({ xr, opacity }, i) => {
          const slideImage = slides[i].image
            ? slides[i].image.localFile.childImageSharp.fixed.src
            : null
          return (
            <animated.div
              key={slides[i].image.id}
              style={{
                backgroundImage: `url('${slideImage}')`,
                transform: xr.interpolate(
                  (x, r) => `translate3d(${x}px,0,0) rotateY(${r}deg)`
                ),
                zIndex: amountSlides - i,
                opacity,
              }}
              className={style.slide}
            >
              <div className={style.slideRight} />
            </animated.div>
          )
        })}
      </div>
      <button
        type="button"
        className={cs(style.nextBtn, 'circle-button')}
        onClick={nextSlide}
      >
        <i className="icon-right-arrow" />
      </button>
    </div>
  )
}

export default VariantsSlider
