import React, { useEffect, useState, useContext } from 'react'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import style from './ProjectsSection.module.scss'
import VerticalSlider from '../VerticalSlider'
import ProjectItem from '../ProjectItem'

const ProjectsSection = ({ options = {}, projects = [], className = '' }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  return projects && projects.length ? (
    <section
      className={cs(
        style.root,
        className,
        'primary-text-section overflow-hidden'
      )}
    >
      <div
        className={`${style.sectionName} primary-text-section__overtitle mb-xxl:mb-8`}
      >
        Наши проекты
      </div>
      <div className="flex flex-wrap h-full items-stretch -mx-6">
        <div className="w-full lg:w-1/2 p-6">
          <VerticalSlider>
            {projects.map(item => (
              <div key={item.id} className="pb-8">
                <ProjectItem
                  image={item.acf.card_image ? item.acf.card_image : null}
                  description={item.acf.short_description}
                  title={item.title}
                  link={item.link}
                />
              </div>
            ))}
          </VerticalSlider>
        </div>
        <div
          ref={ref}
          className={cs('w-full', 'lg:w-1/2', 'p-6', { 'in-view': inView })}
        >
          <h2
            className={`${style.title} primary-text-section__title primary-appearance`}
            style={{ transitionDelay: '150ms' }}
          >
            {options.title}
          </h2>
          <div
            className={`${style.description} primary-description max-w-lg primary-appearance`}
            style={{ transitionDelay: '200ms' }}
            dangerouslySetInnerHTML={{ __html: options.description }}
          />
          {options.button ? (
            <div
              className="mt-6 primary-appearance"
              style={{ transitionDelay: '350ms' }}
            >
              {options.button.type === 'link' ? (
                <Link
                  to={options.button.link}
                  title={options.button.text}
                  className="btn btn--dark-link"
                >
                  {options.button.text}
                </Link>
              ) : (
                <button type="button" className="btn btn--dark-link">
                  <span className="inline-block align-middle">
                    {options.button.text}
                  </span>
                  <i className="ml-2 inline-block align-middle icon-right-arrow text-4xl" />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </section>
  ) : null
}

export default ProjectsSection
