import React from 'react'
import cs from 'classnames'
import Img from 'gatsby-image/withIEPolyfill'
import { animated } from 'react-spring'
import style from './DuCourtSection.module.scss'
import ACFButton from '../ACFButton'
import useScrollX from '../../util/useScrollX'

const trans1 = x => `translate3d(${x * 1.25}px,0,0)`
const trans2 = x => `translate3d(${-x * 1.25}px,0,0)`
const trans3 = x => `translate3d(${x * 0.9}px,0,0)`

const DuCourtSection = ({
  options = {},
  materials_section = {},
  className,
}) => {
  const [{ x }, ref] = useScrollX()

  return (
    <section
      ref={ref}
      className={cs({
        [className]: true,
        // 'showcase-section': true,
      })}
    >
      <div className="h-full flex items-stretch flex-no-wrap">
        <div className="w-auto">
          <div className="showcase-section__big-text">
            <div className="showcase-section__big-text-inner">
              <span dangerouslySetInnerHTML={{ __html: options.left_text }} />
            </div>
          </div>
        </div>
        <div className="md:flex-auto w-full md:w-auto relative primary-text-section flex flex-col">
          <div className="flex flex-wrap items-center -m-4 flex-auto">
            <div className="w-full sm:w-1/2 p-4">
              <h2
                className="showcase-section__title"
                dangerouslySetInnerHTML={{
                  __html: materials_section.title,
                }}
              />
              <div
                className="showcase-section__description mt-6"
                dangerouslySetInnerHTML={{
                  __html: materials_section.description,
                }}
              />
            </div>
            <div className="w-full sm:w-1/2 p-4 relative h-full">
              <animated.div
                style={{ transform: x.interpolate(trans1) }}
                className={style.verticalImage}
              >
                <Img
                  loading="eager"
                  className="absolute-fit"
                  fluid={options.vertical_image.localFile.childImageSharp.fluid}
                />
              </animated.div>
              <animated.div
                style={{ transform: x.interpolate(trans2) }}
                className={style.logoImage}
              >
                <Img
                  loading="eager"
                  className="absolute-fit"
                  fixed={options.logo.localFile.childImageSharp.fixed}
                />
              </animated.div>
            </div>
          </div>
          <div className="flex flex-wrap items-center -mx-4 flex-auto">
            <div className="w-full sm:w-1/3 lg:2/5 xxl:w-1/2 p-4 h-full">
              <animated.div
                style={{ transform: x.interpolate(trans3) }}
                className={style.horizontalImage}
              >
                <Img
                  className="absolute-fit"
                  fluid={
                    options.horizontal_image.localFile.childImageSharp.fluid
                  }
                />
              </animated.div>
            </div>
            <div className="w-full sm:w-2/3 lg:3/5 xxl:w-1/2 p-4">
              <div
                className="showcase-section__description mt-6"
                dangerouslySetInnerHTML={{
                  __html: options.description,
                }}
              />

              {options.button ? (
                <div className="showcase-section__btn mt-4 xxl:mt-8">
                  <ACFButton {...options.button} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DuCourtSection
