import React, { useEffect, useState, useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import cs from 'classnames'
import style from './ServicesSection.module.scss'
import Service from './Service'

const ServicesSection = ({ options = {}, className = '' }) => {
  const [ref, inView] = useInView({
    threshold: 0.2,
    triggerOnce: true,
  })

  return options && options.services && options.services.length ? (
    <section
      ref={ref}
      className={cs({
        [style.root]: true,
        [className]: true,
        'flex flex-wrap': true,
        'in-view': inView,
      })}
    >
      <h2 className={style.title}>{options.title}</h2>
      {options.services.map((service, i) => (
        <Service
          key={service.title}
          className="w-full md:w-1/3"
          transitionDelay={i * 360}
          inView={inView}
          {...service}
        />
      ))}
    </section>
  ) : null
}

export default ServicesSection
