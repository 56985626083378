import React, { useEffect, useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import { useSprings, useTransition, animated } from 'react-spring'
import style from './BorderedSliderSection.module.scss'
import ACFButton from '../ACFButton'

const calculateMaxContainerHeight = textContainers => {
  let maxHeight = 0
  textContainers.forEach(textContainer => {
    if (textContainer) {
      const { height } = textContainer.getBoundingClientRect()
      if (height > maxHeight) {
        maxHeight = height
      }
    }
  })
  return maxHeight
}

const BorderedSliderSection = ({ slides = [], className = '' }) => {
  const textContainers = []
  const [maxTextContainerHeight, setMaxTextContainerHeight] = useState(0)
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const [currentSlide, setCurrentSlide] = useState(-1)
  const amountSlides = slides && slides.length ? slides.length : 0
  const lastSlide = amountSlides - 1
  const prevSlide = () =>
    setCurrentSlide(currentSlide <= 0 ? amountSlides - 1 : currentSlide - 1)

  const nextSlide = () =>
    setCurrentSlide(currentSlide >= lastSlide ? 0 : currentSlide + 1)

  useEffect(() => {
    if (inView) {
      setCurrentSlide(0)
    }
  }, [inView])

  const calculateAndSetMaxContainerHeight = () => {
    setMaxTextContainerHeight(calculateMaxContainerHeight(textContainers))
  }

  useEffect(() => {
    calculateAndSetMaxContainerHeight()
    window.addEventListener('resize', calculateAndSetMaxContainerHeight)
    return window.removeEventListener(
      'resize',
      calculateAndSetMaxContainerHeight
    )
  }, [])

  return slides && slides.length ? (
    <section
      ref={inViewRef}
      className={cs({
        'p-4 lg:p-10': true,
        [className]: className,
      })}
    >
      <div className="bordered-container min-h-full relative flex flex-col">
        <div className="flex-auto flex flex-wrap h-full -my-4 -mx-6 xxl-mx-8">
          <div className="w-full lg:w-2/5 p-4 xl:px-6 xxl:px-8">
            <div className={`${style.imagesContainer} relative h-full`}>
              {slides.map((item, i) =>
                item.bg_image ? (
                  <div
                    key={i}
                    className={cs(style.bgImage, {
                      active: i === currentSlide,
                    })}
                    style={{
                      backgroundImage: `url(${item.bg_image.localFile.childImageSharp.fluid.src})`,
                    }}
                  />
                ) : null
              )}
              {slides.map((item, i) =>
                item.image ? (
                  <div
                    key={i}
                    className={cs(style.image, {
                      active: i === currentSlide,
                    })}
                    style={{
                      backgroundImage: `url(${item.image.localFile.childImageSharp.fluid.src})`,
                    }}
                  />
                ) : null
              )}
            </div>
          </div>
          <div className="w-full lg:w-3/5 p-4 xl:px-6 xxl:px-8">
            <div className={`${style.titles} flex flex-no-wrap -mx-6`}>
              {slides.map((item, i) => (
                <div key={i} className="px-6">
                  <button
                    type="button"
                    className={cs(style.title, {
                      active: i === currentSlide,
                    })}
                    onClick={() => setCurrentSlide(i)}
                  >
                    {item.title}
                  </button>
                </div>
              ))}
            </div>
            <div
              className="relative max-w-2xl mt-8"
              style={{ height: maxTextContainerHeight }}
            >
              {slides.map((item, i) => (
                <div
                  key={i}
                  ref={ref => (textContainers[i] = ref)}
                  className={cs(style.textContainer, {
                    active: i === currentSlide,
                  })}
                >
                  <div
                    className={`${style.description} primary-description primary-appearance-on-active mb-4`}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />

                  {item.button ? (
                    <div
                      className="mt-6 primary-appearance-on-active"
                      style={{ transitionDelay: '150ms' }}
                    >
                      <ACFButton {...item.button} />
                    </div>
                  ) : null}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={style.controlsContainer}>
          <button
            type="button"
            className="circle-button circle-button--alt mr-4"
            onClick={prevSlide}
          >
            <i className="icon-left-arrow" />
          </button>
          <button
            type="button"
            className="circle-button circle-button--alt"
            onClick={nextSlide}
          >
            <i className="icon-right-arrow" />
          </button>
        </div>
      </div>
    </section>
  ) : null
}

export default BorderedSliderSection
