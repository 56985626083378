import React from 'react'
import cs from 'classnames'
import Img from 'gatsby-image/withIEPolyfill'
import { animated } from 'react-spring'
import style from './CoquillageSection.module.scss'
import ACFButton from '../ACFButton'
import useScrollX from '../../util/useScrollX'

const trans1 = x => `translate3d(${x * 1.5}px,0,0)`
const trans2 = x => `translate3d(${-x}px,0,0)`

const CoquillageSection = ({ options = {}, className }) => {
  const [{ x }, ref] = useScrollX()

  return (
    <section
      ref={ref}
      className={cs({ [className]: true, 'showcase-section': true })}
    >
      <div className="h-full flex items-stretch flex-wrap">
        <div className="w-auto">
          <div className="showcase-section__big-text">
            <div className="showcase-section__big-text-inner">
              <span dangerouslySetInnerHTML={{ __html: options.left_text }} />
            </div>
          </div>
        </div>
        <div className="md:flex-auto w-full md:w-auto relative">
          <animated.div
            loading="eager"
            style={{ transform: x.interpolate(trans1) }}
            className={style.largeImage}
          >
            <Img
              className="absolute-fit"
              fluid={options.large_image.localFile.childImageSharp.fluid}
            />
          </animated.div>
          <animated.div
            loading="eager"
            style={{ transform: x.interpolate(trans2) }}
            className={style.smallImage}
          >
            <Img
              className="absolute-fit"
              fixed={options.small_image.localFile.childImageSharp.fixed}
            />
          </animated.div>
        </div>
        <div className="relative z-10 w-full md:w-auto py-6">
          <div className="max-w-sm">
            <h2
              className="showcase-section__title"
              dangerouslySetInnerHTML={{ __html: options.title }}
            />
            <div
              className="showcase-section__description mt-6"
              dangerouslySetInnerHTML={{ __html: options.description }}
            />

            {options.button ? (
              <div className="showcase-section__btn mt-8 lg:mt-12">
                <ACFButton {...options.button} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CoquillageSection
