import React from 'react'
import cs from 'classnames'
import Img from 'gatsby-image/withIEPolyfill'
import { useInView } from 'react-intersection-observer'
import style from './InstagramSection.module.scss'

const InstagramSection = ({ options = {}, className }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })

  const instagramLink = `https://instagram.com/${options.instagram_username ||
    ''}`

  return (
    <section
      ref={ref}
      className={cs({
        [className]: className,
        'p-4 lg:p-10': true,
        'in-view': inView,
      })}
    >
      <div className="bordered-container min-h-full max-w-6xl mx-auto flex flex-col">
        <div className="flex flex-wrap sm:flex-no-wrap items-center -my-2 -mx-4">
          <div className="py-2 px-4 flex-none">
            {options.avatar ? (
              <Img
                loading="eager"
                className={style.image}
                fixed={options.avatar.localFile.childImageSharp.fixed}
              />
            ) : null}
          </div>
          <div className="py-2 px-4 flex-auto">
            <div className="font-serif text-3xl md:text-4xl">
              <h2
                className={`${style.title} primary-text-section__title inline mb-0 leading-tight`}
              >
                Ещё больше свежих фото в нашем
              </h2>
              {' '}
              <a
                href={instagramLink}
                className={`${style.instagramLink} primary-link text-black leading-none`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
            <a
              className={style.username}
              href={instagramLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              @
              {options.instagram_username}
            </a>
          </div>
          <div className="w-full sm:w-auto py-2 px-4">
            <a
              href={instagramLink}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn--large btn--primary"
            >
              Подписаться
            </a>
          </div>
        </div>
        {options.gallery && options.gallery.length ? (
          <div className="mt-auto pt-8 flex flex-wrap -mx-1 lg:-mx-2">
            {options.gallery.map(item => (
              <div key={item.id} className="w-1/2 sm:w-1/5 p-1 lg:p-2">
                <Img
                  loading="eager"
                  className={style.galleryImage}
                  fixed={item.localFile.childImageSharp.fixed}
                />
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </section>
  )
}

export default InstagramSection
