import React from 'react'
import cs from 'classnames'
import Img from 'gatsby-image/withIEPolyfill'
import style from './MiniTextSection.module.scss'

const MiniTextSection = ({ options = {}, className, measureRef }) => (
  <section
    ref={measureRef}
    className={cs({
      [style.section]: true,
      [className]: className,
    })}
  >
    <div className={`${style.inner} flex justify-center`}>
      <div className={`${style.box} flex flex-col flex-nowrap`}>
        <div className="flex-auto">
          <div className="primary-text-section__overtitle mb-4">
            {options.overtitle}
          </div>
          <h2
            className="primary-text-section__title mb-10"
            dangerouslySetInnerHTML={{ __html: options.title }}
          />
          <div
            className="primary-text-section__description mb-4"
            dangerouslySetInnerHTML={{ __html: options.description }}
          />
        </div>
        <div className="flex-none">
          <button type="button" className="btn btn--primary btn--large">
            Связаться с нами
          </button>
        </div>
        <div className={`${style.imageContainer} overflow-hidden mt-6`}>
          <Img fluid={options.image.localFile.childImageSharp.fluid} alt="" />
        </div>
      </div>
    </div>
  </section>
)

export default MiniTextSection
