import React from 'react'
import Link from '../Link'

const ACFButton = ({ type, link = '', text = '', new_tab = false }) => {
  if (type === 'link') {
    return (
      <Link
        to={link}
        title={text}
        target={new_tab ? 'blank' : ''}
        className="btn btn--primary btn--large"
      >
        {text}
      </Link>
    )
  }
  if (type === 'callrequest') {
    return (
      <button type="button" className="btn btn--primary btn--large">
        {text}
      </button>
    )
  }
  return null
}

export default ACFButton
