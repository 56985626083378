import React from 'react'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import Img from 'gatsby-image/withIEPolyfill'
import style from './VariantsSection.module.scss'
import VariantsSlider from './VariantsSlider'
import ACFButton from '../ACFButton'

const VariantsSection = ({ options = {}, className }) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })

  const titleImage = options.image
    ? options.image.localFile.childImageSharp.fixed
    : ''

  return (
    <section
      ref={inViewRef}
      className={cs({
        'p-4 lg:p-10': true,
        [className]: className,
        'in-view': inView,
      })}
    >
      <div className="bordered-container min-h-full flex items-stretch flex-wrap">
        <div className="w-full lg:w-1/2 p-4 sm:p-8 flex flex-col">
          <Img
            loading="eager"
            fixed={titleImage}
            objectFit="contain"
            objectPosition="0 100%"
            alt={options.title}
            className={`${style.image} primary-appearance`}
            style={{ transitionDelay: '150ms' }}
          />
          <div
            className="primary-appearance primary-description my-6 xxl:my-10 max-w-md"
            dangerouslySetInnerHTML={{ __html: options.description }}
            style={{ transitionDelay: '300ms' }}
          />

          {options.button ? (
            <div
              className={`${style.buttonContainer} primary-appearance`}
              style={{ transitionDelay: '450ms' }}
            >
              <ACFButton {...options.button} />
            </div>
          ) : null}
        </div>
        {options.variants ? (
          <VariantsSlider
            slides={options.variants}
            className="w-full lg:w-1/2"
          />
        ) : null}
      </div>
    </section>
  )
}

export default VariantsSection
