import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useSpring, animated } from 'react-spring'
import style from './VerticalSlider.module.scss'

const VerticalSlider = ({ children }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const amountSlides = children && children.length ? children.length : 0
  const containerRef = useRef(null)
  const [containerBounds, setContainerBounds] = useState({
    width: 0,
    height: 0,
    top: 0,
    left: 0,
  })

  const childrenNodes = useMemo(() => new Map(), [children])

  const [childrenBounds, setChildrenBounds] = useState([])

  const lastSlide = amountSlides - 1
  const prevSlide = () =>
    setCurrentSlide(currentSlide <= 0 ? amountSlides - 1 : currentSlide - 1)

  const nextSlide = () =>
    setCurrentSlide(currentSlide >= lastSlide ? 0 : currentSlide + 1)

  const [{ y }, set] = useSpring(() => ({
    y: 0,
    config: { mass: 1, tension: 80, friction: 24 },
  }))

  const [minHeight, setMinHeight] = useState(0)

  const updateBounds = () => {
    setContainerBounds(() => containerRef.current.getBoundingClientRect())
    setChildrenBounds(() => {
      const newChildrenBounds = []
      let newMinHeight = 0
      childrenNodes.forEach((node, i) => {
        if (node && node.getBoundingClientRect) {
          const bounds = node.getBoundingClientRect()
          if (bounds.height + 50 > newMinHeight) {
            newMinHeight = bounds.height + 50
          }
          newChildrenBounds[i] = bounds
          return bounds
        }
        return null
      })
      setMinHeight(newMinHeight)
      return newChildrenBounds
    })
  }

  useEffect(() => {
    updateBounds()
    window.addEventListener('resize', updateBounds)
    return () => window.removeEventListener('resize', updateBounds)
  }, [children])

  useEffect(() => {
    const bounds = childrenBounds[currentSlide]
    if (bounds) {
      set({
        y: containerBounds.top - bounds.top,
      })
    }
  }, [currentSlide, containerBounds, childrenBounds])

  return (
    <div className={style.root} style={{ minHeight }}>
      <animated.div
        ref={containerRef}
        className={style.slides}
        style={{ transform: y.interpolate(y => `translate3d(0,${y}px,0)`) }}
      >
        {React.Children.map(children, (child, i) => (
          <div ref={node => childrenNodes.set(i, node)}>{child}</div>
        ))}
      </animated.div>
      <div className={style.controls}>
        <button
          type="button"
          className={`${style.control} circle-button`}
          onClick={prevSlide}
        >
          <i className="icon-up-arrow" />
        </button>
        <button
          type="button"
          className={`${style.control} circle-button`}
          onClick={nextSlide}
        >
          <i className="icon-down-arrow" />
        </button>
      </div>
    </div>
  )
}

export default VerticalSlider
