import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import style from './ProjectItem.module.scss'
import Link from '../Link'

const ProjectItem = ({ image, title, description, link }) => {
  const preparedDescription =
    description.length > 90 ? `${description.slice(0, 87)}...` : description
  return (
    <article className={style.root}>
      <Link to={link} className="flex flex-wrap -m-2 sm:-m-4 text-gray-900">
        <div className="w-1/3 md:w-2/5 p-2 sm:p-4">
          {image ? (
            <Img
              className={style.image}
              fixed={image.localFile.childImageSharp.fixed}
            />
          ) : null}
        </div>
        <div className="w-2/3 md:w-3/5 p-2 sm:p-4 max-w-md">
          <h3 className="mb-4">{title}</h3>
          <div
            className="primary-description"
            dangerouslySetInnerHTML={{ __html: preparedDescription }}
          />
          <i
            className={`${style.arrow} text-4xl icon-right-arrow mt-6 hidden sm:block`}
          />
        </div>
      </Link>
    </article>
  )
}

export default ProjectItem
