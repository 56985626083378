import { useState, useEffect } from 'react'

let HIDDEN
let VISIBILITY_CHANGE

if (typeof document !== 'undefined') {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    HIDDEN = 'hidden'
    VISIBILITY_CHANGE = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    HIDDEN = 'msHidden'
    VISIBILITY_CHANGE = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    HIDDEN = 'webkitHidden'
    VISIBILITY_CHANGE = 'webkitvisibilitychange'
  }
}

function isVisible() {
  return typeof document !== 'undefined' && !document[HIDDEN]
}

export default () => {
  const [documentVisibility, setDocumentVisibility] = useState(isVisible())

  function handleVisibilityChange() {
    setDocumentVisibility(isVisible())
  }

  useEffect(() => {
    window.addEventListener(VISIBILITY_CHANGE, handleVisibilityChange)
    return () => {
      window.removeEventListener(VISIBILITY_CHANGE, handleVisibilityChange)
    }
  }, [])

  return documentVisibility
}
