import React, { useEffect, useRef } from 'react'
import { useTransition, animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import cs from 'classnames'
import style from './PrimarySlider.module.scss'

const PrimarySliderContainer = ({
  inViewRef = null,
  slides,
  currentSlide,
  // onPrev = f => f,
  // onNext = f => f,
  // gestureRef,
  // width,
}) => {
  const sizeRef = useRef(null)

  const handleRef = node => {
    sizeRef.current = node
  }

  const transitions = useTransition(currentSlide, k => k, {
    from: {
      translateXScale: [-100, 1.1],
    },
    enter: {
      translateXScale: [0, 1],
    },
    leave: {
      translateXScale: [100, 1.1],
    },
    immediate: document.visibilityState === 'hidden',
    config: { mass: 0.4, tension: 25, friction: 8 },
  })

  return (
    <div ref={handleRef} className={style.container}>
      {transitions.map(({ item, key, props: { translateXScale } }) => {
        const slide = slides[item]
        return (
          slide && (
            <animated.div
              key={key}
              style={{
                transform: translateXScale.interpolate(
                  x => `translate3d(${-x}%,0,0)`
                ),
              }}
              className={style.slide}
            >
              <animated.div
                className={style.slideMask}
                style={{
                  transform: translateXScale.interpolate(
                    (x, s) => `translate3d(${x * 0.9}%,0,0) scale(${s})`
                  ),
                }}
              >
                <Img
                  className={style.slideImage}
                  fluid={slide.image.localFile.childImageSharp.fluid}
                  loading="eager"
                  alt=""
                />
              </animated.div>
            </animated.div>
          )
        )
      })}
    </div>
  )
}

export default PrimarySliderContainer
