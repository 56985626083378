import React, { useEffect, useState, useContext } from 'react'
import cs from 'classnames'
import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'
import style from './Service.module.scss'

const Service = ({
  className = '',
  isVisible = true,
  title = '',
  description = '',
  link = '',
  image = '',
  transitionDelay = 0,
}) => (
  <Link
    to={link}
    className={cs({ [style.root]: true, [className]: className })}
  >
    <h3
      className={`${style.title} primary-appearance`}
      style={{ transitionDelay: `${transitionDelay + 150}ms` }}
    >
      {title}
    </h3>
    <div
      className={`${style.description} primary-appearance primary-description mb-8`}
      style={{ transitionDelay: `${transitionDelay + 250}ms` }}
      dangerouslySetInnerHTML={{ __html: description }}
    />
    <div className={style.arrow} />
    <Img
      loading="eager"
      fixed={image.localFile.childImageSharp.fixed}
      objectFit="contain"
      objectPosition="50% 100%"
      alt={title}
      className={`${style.image} primary-appearance`}
      style={{ transitionDelay: `${transitionDelay + 450}ms` }}
    />
  </Link>
)

export default Service
