import React, { useState, useEffect, useRef } from 'react'
import { useSpring, animated } from 'react-spring'
import cs from 'classnames'
import style from './PrimarySlider.module.scss'

const RIGHT_DIRECTION = 1
const LEFT_DIRECTION = -1

const calc = (mouseX, mouseY, scale, cursorBounds, cursorDirection) => {
  const x = mouseX - cursorBounds.x - cursorBounds.width / 2
  const y = mouseY - cursorBounds.y - cursorBounds.height / 2
  const r = cursorDirection === LEFT_DIRECTION ? -180 : 0
  return [x, y, r, scale]
}

const detectCursorDirection = (mouseX, containerBounds) =>
  mouseX - containerBounds.left < containerBounds.width / 2
    ? LEFT_DIRECTION
    : RIGHT_DIRECTION

const replaceScale = (currentValue, scale = 1) => {
  currentValue[3] = scale
  return currentValue
}

const PrimarySliderCursor = ({ onNext = f => f, onPrev = f => f }) => {
  const [cursorDirection, setCursorDirection] = useState(RIGHT_DIRECTION)
  const [containerBounds, setContainerBounds] = useState({
    width: 0,
    height: 0,
  })
  const [cursorBounds, setCursorBounds] = useState(null)
  const containerRef = useRef(null)
  const cursorRef = useRef(null)

  const updateBounds = () => {
    setCursorBounds(cursorRef.current.getBoundingClientRect())
    setContainerBounds(containerRef.current.getBoundingClientRect())
  }

  useEffect(() => updateBounds(), [])

  const [{ xyrs }, setCursorPosition] = useSpring(() => ({
    xyrs: [0, 0, 0, 1],
  }))

  return (
    <div
      role="marquee"
      ref={containerRef}
      className={style.cursorContainer}
      onMouseEnter={() => updateBounds()}
      onMouseLeave={() => setCursorPosition({ xyrs: [0, 0, 0, 1] })}
      onMouseDown={() =>
        setCursorPosition({ xyrs: replaceScale(xyrs.getValue(), 0.75) })
      }
      onMouseUp={() =>
        setCursorPosition({ xyrs: replaceScale(xyrs.getValue(), 1) })
      }
      onMouseMove={({ clientX: x, clientY: y }) => {
        if (containerBounds && cursorBounds) {
          const newCursorDirection = detectCursorDirection(x, containerBounds)
          if (newCursorDirection !== cursorDirection) {
            setCursorDirection(newCursorDirection)
          } else {
            setCursorPosition({
              xyrs: calc(
                x,
                y,
                xyrs.getValue()[3],
                cursorBounds,
                cursorDirection
              ),
            })
          }
        }
      }}
      onClick={() => (cursorDirection === LEFT_DIRECTION ? onPrev() : onNext())}
    >
      <animated.div
        ref={cursorRef}
        style={{
          transform: xyrs.interpolate(
            (x, y, r, s) =>
              `translate3d(${x}px,${y}px,0) rotate(${r}deg) scale(${s})`
          ),
        }}
        className={cs(style.cursor, 'circle-button', 'disable-transition')}
      >
        <i className="icon-right-arrow" />
      </animated.div>
    </div>
  )
}

export default PrimarySliderCursor
